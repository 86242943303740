import React, { FC, useState, useContext } from 'react'
import GeneralButton from '../../components/atoms/GeneralButton';
import InputForm from '../../components/atoms/InputForm'
import Layout from '../../components/Layout';
import { ToastContext } from '../../context/ToastContext';
import { setPassword } from '../../apis/user';
import { navigate } from "gatsby"
import PasswordResetTemplate from '../../templates/PasswordResetTemplate';

const PasswordResetPage: FC = () => {

    const [ newPassword, setNewPassword ] = useState( "" );
    const [ passwordErrorMessages, setPasswordErrorMessages ] = useState<string[]>( [] );
    const [ tokenErrorMessages, setTokenErrorMessages ] = useState<string[]>( [] );
    const [ passwordResetModalVisible, setPasswordResetModalVisible ] = useState( false )
    const { addToast } = useContext( ToastContext )

    const submit = async () => {
        const uid = new URLSearchParams( window.location.search ).get( "uid" )
        const token = new URLSearchParams( window.location.search ).get( "token" )
        if ( !uid || !token ) {
            setTokenErrorMessages( [ "無効なURLです" ] )
            return
        }
        const data = new FormData()
        data.append( "newPassword", newPassword )
        try {
            await setPassword( {
                newPassword,
                uid,
                token,
            } )
            addToast( {
                text: "パスワードを設定しました",
                type: "success",
                position: "center"
            } )
            window.location.href = "club-cloud://"
            navigate( "/login" )

        }
        catch ( e ) {
            if ( e.response.status === 400 ) {
                e.response.data.token ?
                    setTokenErrorMessages( e.response.data.token ) :
                    setTokenErrorMessages( [] )
                e.response.data.password ?
                    setPasswordErrorMessages( e.response.data.password ) :
                    setPasswordErrorMessages( [] )
            }
            else {
                addToast( {
                    text: "パスワードの設定に失敗しました",
                    type: "error",
                    position: "center"
                } )
            }
        }
    }

    return (
        <Layout footerHide>
            <div style={ { padding: 16 } }>
                <h1 style={ { marginBottom: 32 } }>パスワード設定</h1>
                <InputForm
                    label="新しいパスワード"
                    name="newPassword"
                    placeholder="password"
                    type="password"
                    errorMessage={ passwordErrorMessages }
                    value={ newPassword }
                    onChange={ ( name, value ) => setNewPassword( value ) } displayPasswordCondition />
                { !!tokenErrorMessages.length &&
                    <>
                        { tokenErrorMessages.map( error =>
                            <p style={ { marginBottom: 4, color: "red" } }>{ error }</p> ) }
                        < p style={ { marginBottom: 8, color: "red" } }>もう一度パスワード再発行申請をしてください</p>
                        <GeneralButton
                            title="パスワード再発行申請をする"
                            onClick={ () => setPasswordResetModalVisible( true ) } />
                    </>
                }
                { passwordResetModalVisible &&
                    <PasswordResetTemplate
                        url="/api/users/password/reset/"
                        initialEmail={ "" }
                        onSucceeded={ () => setPasswordResetModalVisible( false ) }
                        onCancel={ () => setPasswordResetModalVisible( false ) } /> }
                { !tokenErrorMessages.length &&
                    <GeneralButton title="送信" onClick={ submit } style={ { marginTop: 8 } } /> }
            </div>
        </Layout>
    )
}
export default PasswordResetPage
